/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-21",
    versionChannel: "nightly",
    buildDate: "2024-08-21T00:05:50.477Z",
    commitHash: "2889a715ad7979370dcc069d70abea09c48045da",
};
